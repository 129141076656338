<template>
  <v-sheet class="primary-gradient">
    <v-container class="py-4">
      <v-row
        align="center"
        dense
      >
        <v-col
          class="pa-md-0 pb-3"
          cols="12"
          md="3"
        >
          <span class="white--text body-2">Fique por dentro das nossas</span>
          <h4 class="text-h4 font-weight-bold text-uppercase white--text">
            Novidades
          </h4>
        </v-col>
        <v-col
          class="pa-0"
          cols="12"
          md="9"
        >
          <v-form
            :class="$vuetify.breakpoint.mobile ? '' : 'd-flex align-center'"
          >
            <v-text-field
              hide-details
              v-model="contact.name"
              class="mx-1 flex-grow-1"
              placeholder="Nome*"
              rounded
              solo
              flat
              dense
            />
            <v-text-field
              hide-details
              v-model="contact.email"
              class="mx-1"
              placeholder="Email*"
              type="email"
              rounded
              solo
              flat
              dense
            />
            <v-text-field
              hide-details
              v-model="contact.company_name"
              class="mx-1"
              placeholder="Empresa"
              rounded
              solo
              flat
              dense
            />
            <v-btn
              class="mx-1"
              @click="postEmailNews"
              v-text="'Cadastrar'"
              color="white"
              rounded
              outlined
            />
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script>
export default {
  data() {
    return {
      contact: {},
    }
  },
  methods: {
    postEmailNews() {
      this.$api
        .post('homes/email_new', this.contact)
        .then((res) => {
          this.$store.commit('setModal', {
            show: true,
            component: 'success',
            text: res.data.message,
            confirm: () => {
              this.$store.commit('setModal', {
                show: false,
              })
            },
          })
        })
        .catch((err) => {
          let messageError = ''
          if (err.response.data.errors) {
            if (err.response.data.errors.email) {
              messageError = err.response.data.errors.email[0]
            }
          } else {
            messageError = 'Não foi possível fazer seu cadastro na newsletter'
          }
          this.$store.commit('setModal', {
            show: true,
            component: 'error',
            text: err.response.data.message,
            caption: messageError,
            confirm: () => {
              this.$store.commit('setModal', {
                show: false,
              })
            },
          })
        })
        .finally(() => {
          this.dataTable.isLoading = false
        })
    },
  },
}
</script>
